<template>
    <li class="task-item" :class="{ 'task-item--completed': task.completed, 'task-item--blocked': task.blocked }">
        <a :target="getTargetType(task)" class="task-item__link" :href="task.url">
            <div class="task-item__content">
                <h3 class="task-item__title">
                    <span v-if="task.status !== 'active'" class="task-item__status label" :class="`label--${task.status}`">{{ `status.label.${task.status}`|trans() }}</span>{{ task.title }}
                </h3>
                <p class="task-item__action">
                    {{ task.action }}
                </p>
            </div>
            <span v-if="!task.blocked">
                <i
                    v-if="task.completed"
                    :title="$trans('task.completed', {}, 'task_list_frontend')"
                    class="task-item__icon task-item__icon--completed fa fas fa-check-circle"
                />
                <i v-else class="task-item__icon fa fas fa-arrow-right" />
            </span>
        </a>
        <div v-if="task.blocked" class="task-item__blocked-overlay">
            <div class="task-item__content">
                <h3 class="task-item__title">
                    {{ task.title }}
                </h3>
                <p class="task-item__action">
                    {{ 'task.available'|trans({'%taskTitle%': `"${getBlockingTaskTitle()}"`}, 'task_list_frontend') }}
                </p>
            </div>
            <i class="task-item__icon fa fas fa-lock" />
        </div>
    </li>
</template>

<script>
    export default {
        name: 'TaskItem',
        props: {
            task: {
                type: Object,
                required: true,
            },
            blockingTask: {
                type: Object,
                required: false,
            },
            categoryStatus: {
                type: Object,
                required: false,
            },
        },
        methods: {
            getTargetType(task) {
                return task.task_type === 'external_task' ? '_blank' : '_self';
            },
            getBlockingTaskTitle() {
                if (!this.blockingTask) {
                    if (this.categoryStatus.status === 'blocked') {
                        return this.categoryStatus.categoryName;
                    }
                }
                return this.blockingTask ? this.blockingTask.title : '';
            },
        },
    };
</script>

<style lang="scss" scoped>
    .task-item {
        display: inline-flex;
        width: 100%;

        &--completed {
            background: #F1F8E9;
        }

        &--blocked {
            position: relative;

            /deep/ .task-item {
                &__link {
                    opacity: 0.97;
                    position: absolute;
                }
            }
        }

        &--small {
            /deep/ .task-item {
                &__link {
                    padding-bottom: calc(#{$gutter-m} + #{$gutter-s});
                    padding-top: calc(#{$gutter-m} + #{$gutter-s});
                }
            }
        }

        &--sidebar {
            /deep/ .task-item {
                &__link {
                    padding-left: $gutter-m;
                    padding-right: $gutter-m;
                }

                &__blocked-overlay {
                    padding-left: $gutter-m;
                    padding-right: $gutter-m;
                }
            }
        }

        &__link {
            align-items: center;
            color: $color-text-base;
            display: flex;
            font-size: $font-s;
            height: 100%;
            left: 0;
            padding: $gutter-l;
            top: 0;
            width: 100%;

            &:hover {
                background-color: var(--color-primary-tint-85);
            }
        }

        &__content {
            flex-grow: 1;
        }

        &__title {
            font-size: inherit;
            font-weight: 600;
            line-height: 1.3;
        }

        &__status {
            display: inline-block;
            margin-right: 4px;
        }

        &__action {
            color: $color-grey-darker;
            margin-bottom: 0;
            margin-top: $gutter-xs;
        }

        &__icon {
            display: inline-flex;
            flex-shrink: 0;
            font-size: 18px;
            margin: auto 0 auto $gutter-l;

            &--completed {
                color: $color-green-grassy;
            }
        }

        &__blocked-overlay {
            align-items: center;
            background: #F7F7F7;
            color: $color-text-base;
            display: flex;
            font-size: $font-s;
            height: 100%;
            margin: 0 1px;
            padding: $gutter-l;
            width: 100%;
            z-index: 1;
        }
    }
</style>
